import {Homeowner, StreetName} from "types";
import moment from "moment";

export const SCHEDULE_START_DATE = "2021-11-30T21:08:30-07:00";
export const FIRST_TRASH_DAY = moment(SCHEDULE_START_DATE).isoWeekday(2);

const HOME_3478 = {
    names: 'Laura & Antrim',
    address: {
        streetName: StreetName.SCHOOL_ST,
        streetNumber: 3478,
    }
}

const HOME_3101 = {
    names: 'Angelica & Sergey',
    address: {
        streetName: StreetName.THIRTY_FIFTH_AVE,
        streetNumber: 3101,
    }
}

const HOME_3472 = {
    names: 'Emily & Ben',
    address: {
        streetName: StreetName.SCHOOL_ST,
        streetNumber: 3472,
    }
}

const HOME_3468 = {
    names: 'Theresa & John',
    address: {
        streetName: StreetName.SCHOOL_ST,
        streetNumber: 3468,
    }
}

const HOME_3482 = {
    names: 'Shola & Matthew',
    address: {
        streetName: StreetName.SCHOOL_ST,
        streetNumber: 3482,
    }
}

const HOME_3498 = {
    names: 'Theresa K',
    address: {
        streetName: StreetName.SCHOOL_ST,
        streetNumber: 3498,
    }
}

const HOME_3103 = {
    names: 'Phuc',
    address: {
        streetName: StreetName.THIRTY_FIFTH_AVE,
        streetNumber: 3103,
    }
}

export const Homeowners: Homeowner[] = [HOME_3478, HOME_3101, HOME_3472, HOME_3468, HOME_3482, HOME_3498, HOME_3103];

export const overrides = {
    'Nov 28, 2023': HOME_3101,
    'Dec 12, 2023': HOME_3103,
    'Mar 12, 2024': HOME_3101,
    'Mar 19, 2024': HOME_3478,
    'Jun 4, 2024': HOME_3101,
    'Jun 25, 2024': HOME_3498,
}
